<!--
 * @Description: 车行道停车运营管理 运营管理 黑白名单 黑名单 blackList
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 14:38:24  
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
                <i class="el-icon-search el-input__icon"
                   slot="prefix">
                </i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>黑名单车辆管理</span>
        <span class="tableTitleButton topButton">
          <el-button type="success"
                     @click="addVehicles">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="exportFile">导 出</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'
                  :tableFun='tableFun'>
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="黑名单状态"
                             prop='blackWhiteListStatus'>
              <template slot-scope="scope">
                <span :class="blackWhiteListStatus(scope.row.blackWhiteListStatus)">
                  {{scope.row.blackWhiteListStatus == '1'?'生效中':'已失效'}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="outOfCommission(scope.row)"
                               type="text"
                               size="medium">
                      {{scope.row.blackWhiteListStatus === 1?'停用':'启用'}}
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleOrderUpdate(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleOrderDelete(scope.row)"
                               type="text"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 修改 -->
    <el-dialog :title="this.dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form label-width="125px"
               :model="addForm"
               ref="addForm"
               :rules="rules"
               clearValidate
               :inline="true">
        <el-row justify="space-around"
                style="margin-bottom:20px;">
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select clearable
                         v-model="addForm.numberPlateColorCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="addForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="margin-bottom:20px;">
          <el-col :span="12">
            <el-form-item label="加入黑名单理由"
                          prop="joinReason">
              <el-input v-model="addForm.joinReason"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车主姓名"
                          prop="carOwnerName">
              <el-input v-model="addForm.carOwnerName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="margin-bottom:20px;">
          <el-col :span="12">
            <el-form-item label="手机号"
                          prop="phoneNumber">
              <el-input v-model="addForm.phoneNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证信息"
                          prop="idCardNumber">
              <el-input v-model="addForm.idCardNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="margin-bottom:20px;">
          <el-col :span="12">
            <el-form-item label="车主联系地址"
                          prop="carOwnerContactAddress">
              <el-input v-model="addForm.carOwnerContactAddress"
                        placeholder="请输入"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row style="margin-right: 10px;"
              class="dialog-button">
        <el-button @click="dialogVisible = false"
                   type="info">取 消</el-button>
        <el-button type="primary"
                   @click="handleClose('addForm')"
                   style="margin-right:10px">确 定</el-button>
      </el-row>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary"
                     @click="queryTemplateDl">
            模板下载
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable, },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'numberPlateColor',
          label: '车牌颜色',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'joinReason',
          label: '加入黑名单原因',
        },
        {
          prop: 'remark',
          label: '备注',
        },
      ],
      addForm: {}, //新增表单
      parkNameList: [],//停车场名称
      massDeleteList: [],     //表格多选
      dialogVisible: false, //弹出层
      dialogType: '', //弹出层性质：0为新增，1为修改
      dialogTitle: '', //弹出框标题
      colorList: [],//颜色列表
      //校验
      rules: {
        plateNumber: [{ required: true, message: '车牌号不能为空', trigger: 'blur' },
        {
          pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/,
          message: '请输入正确的车牌号',
          trigger: 'blur'
        }],
        numberPlateColorCode: [{ required: true, message: '车牌颜色不能为空', trigger: 'change' }],
        joinReason: [{ required: true, message: '加入黑名单理由不能为空', trigger: 'blur' }],
        //手机号校验
        phoneNumber: [{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        //身份证号
        idCardNumber: [{ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'blur' }]
      },

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getColorDict()
  },
  //方法集合
  methods: {
    queryTemplateDl () {
      let arr = new Array()
      arr[0] = "parkId"
      arr[1] = "effectiveTime"
      arr[2] = "expirationTime"
      this.$axios.get(this.$downloadBaseUrl + "BlackWhiteListController/downloadTemplateBlack?param=" + encodeURI(JSON.stringify(arr)), {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      })
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "黑名单车辆模板.xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
        })
    },
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "BlackWhiteListController/uploadBlack";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 导出
    exportFile () {
      this.$axios.get(this.$downloadBaseUrl + "/BlackWhiteListController/downloadInBlack", {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
        params: { param: {} }
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "黑名单车辆.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    //查找数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$onRoadblackList.queryBlackListNoAuth(this.searchForm).then((response) => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      })
    },

    //查询
    onSubmit () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //新增列表
    add () {
      this.$onRoadblackList.blackWhiteInsert(this.addForm).then((response) => {
        if (response.resultCode === '2000') {
          this.$message({ message: '新增成功', type: 'success' });
          this.dialogVisible = false
          this.queryTableList()
        }
      })
    },
    //修改数据
    update () {
      this.addForm["blackWhiteListStatus"] = 1
      this.$onRoadblackList.blackWhiteUpdate(this.addForm).then((response) => {
        if (response.resultCode === '2000') {
          this.$message({ message: '修改成功', type: 'success' });
          this.dialogVisible = false
          this.queryTableList()
        }
      })
    },
    // 删除记录
    handleOrderDelete (row) {
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [
          {
            listId: row.listId,
          }
        ]
        this.$onRoadblackList.blackWhiteDelete(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '刪除成功', type: 'success' });
            this.queryTableList()
          }
        })
      })
    },
    //表格多选按钮
    handleChange (val) {
      let arr = []
      val.forEach(item => {
        arr.push({
          listId: item.listId
        })
      })
      this.massDeleteList = arr
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //重置表单
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },

    //新增停车场按钮
    addVehicles () {
      this.addForm = {
        listType: '1',
      }
      this.dialogVisible = true
      this.dialogTitle = '新增黑名单'
      this.dialogType = 0
    },
    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({
          showClose: true,
          message: '至少勾选一条记录',
          type: 'error'
        });
      } else {
        this.$confirm('确认批量删除黑名单车辆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$onRoadblackList.blackWhiteDelete(this.massDeleteList).then(response => {
            if (response.resultCode === '2000') {
              this.$message({
                message: '批量删除成功',
                type: 'success'
              });
            }
            this.queryTableList()
          })
        })
      }
    },
    // 修改记录
    handleOrderUpdate (row) {
      this.dialogType = 1
      this.dialogTitle = '黑名单修改'
      this.dialogVisible = true
      this.addForm = row
    },

    //停用记录
    outOfCommission (row) {
      let info = {
        listId: row.listId,
      }
      if (row.blackWhiteListStatus == '1') {
        this.$onRoadblackList.disable(info).then(() => {
          this.$message({
            message: '停用成功',
            type: 'success'
          });
          this.queryTableList()
        })
      } else if (row.blackWhiteListStatus == '2') {
        // 启用
        this.$onRoadblackList.enable(info).then(() => {
          this.$message({ message: '启用成功', type: 'success' });
          this.queryTableList()
        })
      }
    },
    //状态颜色变化
    blackWhiteListStatus (param) {
      let res = "";
      if (param == '1') {
        res = "effective";
      } else if (param == '2') {
        res = "effectiveness";
      }
      return res;
    },
    //弹出框确定按钮
    handleClose (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 0) {
            this.add()
          } else if (this.dialogType == 1) {
            this.update()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 95.5px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
//生效字颜色
.effective {
  color: #bae8ff;
}
//失效字颜色
.effectiveness {
  color: #f56c6c;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  border: none;
}
.dialog-button .el-button {
  float: right;
}
</style>
